import React, {
    Fragment
  } from 'react'  
import update from 'immutability-helper'
import styled from 'styled-components'
import OfferProductsSetting from './offerProductsSetting'
import {
    LegacyCard,
    TextField,
    FormLayout,
    TextContainer,
    Text,
  } from "@shopify/polaris"
import OfferTypeSelector from "../common/offerTypeSelector/offerTypeSelector";
import CardWrapper from '../../../../components/cardWrapper'

function ProductTab(props) {
    const { host, shop, token, state, setState, productPickerShow, error, setError, currency, currencySymbol, productEdit, setProductEdit, checkoutOfferType, setCheckoutOfferType, shopBrainType, existingOffer } = props;
 
    return (
        <Fragment>
          <CardWrapper title="Name your Upsell" subtitle="Name your Upsell, Product Selection">
              <FormLayout>
              <TextField
                  label="Internal offer nickname"
                  labelHidden={true}
                  placeholder="Shoelaces offer+"
                  onChange={(val) => {
                    setState(update(state, { name: { $set: val }}))
                    }}
                  value={state.name}
                  />
                {state.id ?
                  <TextContainer spacing="tight">
                    <Text tone="subdued">Offer ID: {state.id}</Text>
                  </TextContainer>
                  :
                  false
                }
              </FormLayout>

              <OfferTypeSelector
                shopBrainType={shopBrainType}
                checkoutOfferType={checkoutOfferType}
                setCheckoutOfferType={setCheckoutOfferType}
                state={state}
                setState={setState}
                existingOffer={existingOffer}
              />
            </CardWrapper>
            <OfferProductsSetting host={host} shop={shop} token={token} state={state} setState={setState} productPickerShow={productPickerShow} error={error} setError={setError} productEdit={productEdit} setProductEdit={setProductEdit} currency={currency} currencySymbol={currencySymbol} checkoutOfferType={checkoutOfferType}/>
            </Fragment>
    )
}

export default ProductTab;